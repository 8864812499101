import React, { Component } from 'react';

export class TotalsItem extends Component {

	formatPrice(value) {
		value = parseFloat(value);
		value = value.toFixed(2)
		return value;
	}

	render() {
		const p = this.props.product;

		return (
			<React.Fragment>
				<tr>
					<td className="products__total-name" colSpan="3">
						{p.brand} - {p.title}
					</td>
				</tr>
				<tr className="products__totals-row">
					<td className="text-right">R{this.formatPrice(p.case_price_vat)}</td>
					<td className="text-right">R{this.formatPrice(p.cost_per_portion_vat)}</td>
					<td className="text-right">R{this.formatPrice(p.rsp_per_meal)} </td>
				</tr>
			</React.Fragment>
		);
	}
}

export default TotalsItem
