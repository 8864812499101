import React, { PureComponent } from 'react'

export class TotalsHeader extends PureComponent {
	render() {
		return (
		<thead>
			<tr>
				<th className="products__total-th" scope="col">Total Case Cost</th>
				<th className="products__total-th" scope="col">Cost Per Portion</th>
				<th className="products__total-th" scope="col">RSP/Meal</th>
			</tr>
		</thead>
		)
	}
}

export default TotalsHeader
