import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

export class SendMail extends Component {

	handleAgentInfo = (e) => {
		this.props.handleAgentInfo(e);
	}
	emailTotals = () => {
		this.props.emailTotals();
	}

	render() {
		return (
			<div className="content-box mb-3">
				<h2 className="h3 strong mb-3">
					Send email to client
				</h2>
				<p className="mb-3">
					Send an email with all the currently compiled information to a specified email address.
					<br />
					Please make sure you complete your details as well below.
					The app will save your details for next time once you send the mail.
				</p>

				<div className="row">
					<div className="col-sm-6 mb-3">
						<TextField
							label="Site Name"
							value={this.props.data.siteName}
							type="text"
							id="site-name"
							fullWidth={true}
							variant="outlined"
							onChange={ (e) => this.handleAgentInfo(e) }
						/>
					</div>
					<div className="col-sm-6 mb-3">
						<TextField
							label="Client Email"
							value={this.props.data.siteEmail}
							type="email"
							id="site-email"
							fullWidth={true}
							variant="outlined"
							onChange={ (e) => this.handleAgentInfo(e) }
						/>
					</div>
					<div className="col-sm mb-3 mb-sm-0">
						<TextField
							label="Your Name"
							value={this.props.data.consultantName}
							type="text"
							id="consultant-name"
							fullWidth={true}
							variant="outlined"
							onChange={ (e) => this.handleAgentInfo(e) }
						/>
					</div>
					<div className="col-sm mb-3 mb-sm-0">
						<TextField
							label="Your Contact Number"
							value={this.props.data.consultantContact}
							type="text"
							id="consultant-contact"
							fullWidth={true}
							variant="outlined"
							onChange={ (e) => this.handleAgentInfo(e) }
						/>
					</div>
					<div className="col-sm mb-3 mb-sm-0">
						<TextField
							label="Your Email"
							value={this.props.data.consultantEmail}
							type="email"
							id="consultant-email"
							fullWidth={true}
							variant="outlined"
							onChange={ (e) => this.handleAgentInfo(e) }
						/>
					</div>
					<div className="col-sm mb-3 mb-sm-0">
							<button disabled={this.props.data.emailButtonClick} className="btn btn-secondary btn-block btn-lg" onClick={() => this.emailTotals() }>
							{ (this.props.data.emailButtonClick ) &&
								<CircularProgress size={10} color="inherit" className="mr-3" />
							}
								Email Totals
							</button>
					</div>
				</div>
			</div>
		)
	}
}

export default SendMail
