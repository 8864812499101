import React, { Component } from "react";
import axios from "axios";
import Search from "../Search/Search";
import CONFIG from "../../config.json";

export class Products extends Component {
	url = CONFIG.SERVER_URL + 'wp-json/wp/' + CONFIG.API_VERSION + '/';
	pagination = CONFIG.PAGINATION;

	constructor(props) {
		super(props);

		this.state = {
			products: [],
			product_brands: [],
			spreads: [],
			spread_brands: [],
			combined: []
		};

		this.fetchPostData = this.fetchPostData.bind(this);
		this.setBrandNames = this.setBrandNames.bind(this);
		this.setSpreadBrandNames = this.setSpreadBrandNames.bind(this);
		this.combineData = this.combineData.bind(this);
	}

	componentDidMount() {
		this.fetchPostData();
	}

	fetchPostData() {
		let one = this.url+'products'+this.pagination,
			two = this.url+'spreads'+this.pagination,
			three = this.url+'product_brand'+this.pagination,
			four = this.url+'spread_brand'+this.pagination;

		const requestOne = axios.get(one),
			requestTwo = axios.get(two),
			requestThree = axios.get(three),
			requestFour = axios.get(four);

		axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...res) => {
			this.setState({
				products: res[0].data,
				spreads: res[1].data,
				product_brands: res[2].data,
				spread_brands: res[3].data,
			});
		})).catch(errors => {
			console.log(errors);
		}).then(() => {
			this.setBrandNames();
			this.setSpreadBrandNames();
			this.combineData();
		});
	}


	setBrandNames() {
		let products = this.state.products,
			brands = this.state.product_brands;

		products.map( product => {
			let brandID = product.product_brand[0];

			brands.map( brand => {
				if(brandID === brand.id) {
					product.product_brand = brand.name;
				}
				return false;
			})
			return false;
		});

		this.setState({
			products: products
		});
	}
	setSpreadBrandNames() {
		let spreads = this.state.spreads,
			brands 	= this.state.spread_brands;

		spreads.map( product => {
			let brandID = product.spread_brand[0];

			brands.map( brand => {
				if(brandID === brand.id) {
					product.product_brand = brand.name;
				}
				return false;
			})
			return false;
		});

		this.setState({
			spreads: spreads
		});
	}

	combineData() {
		const combined = [
			...this.state.products,
			...this.state.spreads
		];
		const products = [];

		combined.map( item => {
			var type = '',
				brand_logo = '';

			if(item.product_brand) {
				type = CONFIG.PRODUCTTYPES[0];
			}
			if(item.spread_brand) {
				type = CONFIG.PRODUCTTYPES[1];
			}
			if(item.product_brand.length > 0) {
				brand_logo = process.env.PUBLIC_URL + CONFIG.BRAND_LOGO_PATH + item.product_brand.toLowerCase() + '.jpg';
			} else {
				type = CONFIG.PRODUCTTYPES[0];
			}

			const product = {
				// From WP REST
				id: item.id,
				brand : item.product_brand,
				brandlogo : brand_logo,
				fulltitle: item.product_brand +' '+ item.title.rendered,
				title: item.title.rendered,
				acf : item.acf,
				product_type : type,
				thumbnail : item.featured_image_thumbnail,

				// From product items
				case_price : 1,
				case_price_vat : 1.15,
				unit_price : 1,
				unit_price_vat : 1.15,

				cost_per_portion: 0,
				cost_per_portion_vat: 0,

				portions_per_meal: 1,
				total_meal_cost: 0,
				total_meal_cost_vat: 0,
				percentage_gp: 25,
				rsp_per_meal: 0
			};

			products.push(product);
			return false;
		});

		products.sort(this.compare);

		this.setState({
			combined: products
		});
	}


	compare(a, b) {
		const itemA = a.fulltitle.toUpperCase();
		const itemB = b.fulltitle.toUpperCase();

		let comparison = 0;
		if (itemA > itemB) {
			comparison = 1;
		} else if (itemA < itemB) {
			comparison = -1;
		}

		return comparison;
	}


	render() {
		return (
			<div className="container">
				<Search data={this.state.combined} />
			</div>
		);
	}
}

export default Products;
