import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Utils from '../Utils';

export class PlateCostTotals extends Component {

	render() {
		return (
			<table className="products__totals / content-box">
				<thead>
					<tr>
						<th scope="col"></th>
						<th scope="col" className="text-right">
							Excl. VAT
						</th>
						<th scope="col" className="text-right">
							Incl. VAT
						</th>
					</tr>
				</thead>

				<tbody>
					<tr>
						<th scope="row" className="platecostname">
							Total Plate Cost
						</th>
						<td className="platecostvalue totalamount">
							R{Utils.formatPrice(this.props.data.totalPlateCost)}
						</td>
						<td className="platecostvalue totalamount">
							R{Utils.formatPrice( Utils.addVat(this.props.data.totalPlateCost) )}
						</td>
					</tr>

					<tr>
						<th scope="row" className="platecostname">
							Percentage GP
						</th>
						<td className="platecostvalue" colSpan="2">
							<TextField
								label="Percentage GP"
								value={ this.props.data.percentageGP }
								id="percentage-gp"
								type="number"
								fullWidth={true}
								size="small"
								variant="outlined"
								onChange={ this.props.handlePlateCostTotalsChange }
							/>
						</td>
					</tr>


					<tr>
						<th scope="row" className="platecostname">
							Recommended RSP
						</th>
						<td className="platecostvalue totalamount">

						</td>
						<td className="platecostvalue totalamount">
							R{Utils.formatPrice(this.props.data.recommendedRSP)}
						</td>
					</tr>


					<tr>
						<th scope="row" className="platecostname">
							Meals/Day
						</th>
						<td className="platecostvalue" colSpan="2">
							<TextField
								label="Meals / Day"
								value={ this.props.data.mealsPerDay}
								id="meals-per-day"
								type="number"
								fullWidth={true}
								size="small"
								variant="outlined"
								onChange={ this.props.handlePlateCostTotalsChange }
							/>
						</td>
					</tr>
				</tbody>

				<tfoot>
					<tr>
						<th scope="row" className="platecostname">
							Total Meal Revenue
						</th>
						<td className="platecostvalue totalamount">

						</td>
						<td className="platecostvalue totalamount">
							R{Utils.formatPrice(this.props.data.totalMealRevenue)}
						</td>
					</tr>
				</tfoot>
			</table>
		)
	}
}

export default PlateCostTotals
