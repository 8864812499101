import React, { PureComponent } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export class SearchHeader extends PureComponent {
	constructor(props) {
		super(props);

		this.handleSearchSuggestion = this.handleSearchSuggestion.bind(this);
		this.handleClearItems = this.handleClearItems.bind(this);
	}

	handleSearchSuggestion(e, value) {
		this.props.handleSearchSuggestion(e, value)
	}

	handleClearItems () {
		this.props.handleClearItems();
	};

	render() {
		return (
			<div className="search__header-wrapper / mb-3">
				<div className="search__header p-2">
					<div className="flex-item mr-sm-2 mb-2 mb-sm-0">
						<Autocomplete
							id="search"
							options={this.props.data}
							clearOnBlur
							selectOnFocus
							getOptionLabel={(option) => option.fulltitle}
							renderInput={(params) => <TextField {...params} label="Search for a product..." variant="outlined" />}
							onChange={(e, value) => this.handleSearchSuggestion(e, value) }
						/>
					</div>
					<button type="button" onClick={(e) => this.handleClearItems()} className="btn btn-lg btn-secondary btn-block-xs">
						Clear Current Selection
					</button>
				</div>
			</div>
		)
	}
}

export default SearchHeader
