import React, { Component } from 'react';
import Logo from '../../images/logo-white.svg';
import { NavLink } from "react-router-dom";

export class Masthead extends Component {

	render() {
		return (

			<div className="row align-items-center mb-3">

				<div className="col col-12 col-sm-3 / mb-3 mb-sm-0 text-center">
					<img className="site-logo" src={Logo} alt="RCL Food Partners" />
				</div>

				<div className="col col-12 col-sm">
					<ol className="masthead">
						<li>
							<NavLink to="/products" className="btn btn-primary" activeClassName="is-active">
								Products
							</NavLink>
						</li>
						<li>
							<NavLink to="/platecost" className="btn btn-primary" activeClassName="is-active">
								Plate Cost
							</NavLink>
						</li>

						<li>
							<NavLink to="/totals" className="btn btn-primary" activeClassName="is-active">
								Totals
							</NavLink>
						</li>

						<li>
							<NavLink to="/email" className="btn btn-primary" activeClassName="is-active">
								Email
							</NavLink>
						</li>
					</ol>
				</div>
			</div>

		)
	}
}

export default Masthead
