import React, { Component } from 'react';
import Ingredient from './Ingredient';

export class PlateCost extends Component {

	render() {
		return (
			<React.Fragment>
			<div className="content-box mb-3">
				<section className="mb-3">
					<h2 className="h3 strong mb-3">Proteins</h2>
					<Ingredient
						handleAddIngredient={this.props.handleAddIngredient}
						handlePriceIngredient={this.props.handlePriceIngredient}
						ingredients={this.props.data.proteins}
						chosenIngredient={ this.props.data.addedProteins[0] }
						// chosenIngredientPrice={ this.props.data.addedProteinsCost[0] }
						index={0}
						type={'Proteins'}>
					</Ingredient>
				</section>

				<section className="mb-3">
					<h2 className="h3 strong mb-3">Starches</h2>
					<Ingredient
						handleAddIngredient={this.props.handleAddIngredient}
						handlePriceIngredient={this.props.handlePriceIngredient}
						ingredients={this.props.data.starches}
						chosenIngredient={ this.props.data.addedStarches[0] }
						// chosenIngredientPrice={ this.props.data.addedStarchesCost[0] }
						index={0}
						type={'Starches'}>
					</Ingredient>
					<Ingredient
						handleAddIngredient={this.props.handleAddIngredient}
						handlePriceIngredient={this.props.handlePriceIngredient}
						ingredients={this.props.data.starches}
						chosenIngredient={ this.props.data.addedStarches[1] }
						// chosenIngredientPrice={ this.props.data.addedStarchesCost[1] }
						index={1}
						type={'Starches'}>
					</Ingredient>
				</section>


				<section className="mb-3">
					<h2 className="h3 strong mb-3">Sides</h2>
					<Ingredient
						handleAddIngredient={this.props.handleAddIngredient}
						handlePriceIngredient={this.props.handlePriceIngredient}
						ingredients={this.props.data.sides}
						chosenIngredient={ this.props.data.addedSides[0] }
						// chosenIngredientPrice={ this.props.data.addedSidesCost[0] }
						index={0}
						type={'Sides'}>
					</Ingredient>
					<Ingredient
						handleAddIngredient={this.props.handleAddIngredient}
						handlePriceIngredient={this.props.handlePriceIngredient}
						ingredients={this.props.data.sides}
						chosenIngredient={ this.props.data.addedSides[1] }
						// chosenIngredientPrice={ this.props.data.addedSidesCost[1] }
						index={1}
						type={'Sides'}>
					</Ingredient>
				</section>
			</div>

			</React.Fragment>

		)
	}
}

export default PlateCost
