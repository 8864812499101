import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Utils from '../Utils';

export class ProductItem extends Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (index, event) => {
		this.props.handleChange(index, event);
	};


	render() {
		const p = this.props.product;
		const id = this.props.index;

		return (
			<div className="product__item-wrapper / col-sm-6 col-md-4 / mb-3">
				<div className="product__item content-box p-2">

					<header className="product__header p-2 mb-3">
						<div>
							<img className="product__logo" src={p.brandlogo}  alt={p.brand} />
						</div>

						<h2 className="h3 text-center mb-0">{p.title}</h2>
					</header>

					<div className="product__thumbnail-wrapper mb-2">
					{ p.thumbnail &&
						<img className="product__thumbnail" src={p.thumbnail} alt={p.title} />
					}
					</div>

					<div className="table__product-details-wrapper p-2 mb-3">
						<table className="table__product-details">
						<thead>
							<tr>
								<th scope="row">Unit of Measure:</th>
								<td colSpan="2"> {p.acf.unit_of_measure.join(', ')} </td>
							</tr>
							{ p.acf.pack_size &&
							<tr>
								<th scope="row">Pack Size: </th>
								<td colSpan="2">{p.acf.pack_size / 1000} Kgs </td>
							</tr> }
							{ p.acf.number_of_units &&
							<tr>
								<th scope="row">Units/Case:</th>
								<td colSpan="2">{p.acf.number_of_units}</td>
							</tr> }
							{ p.acf.portions_per_case &&
							<tr>
								<th scope="row">Portions/case: </th>
								<td colSpan="2">{p.acf.portions_per_case}</td>
							</tr> }
							{ p.acf.portions_per_unit &&
							<tr>
								<th scope="row">Portions/unit: </th>
								<td colSpan="2">{p.acf.portions_per_unit}</td>
							</tr> }
							{ p.acf.average_portion_weight &&
							<tr>
								<th scope="row">Average Portion Weight: </th>
								<td colSpan="2">{p.acf.average_portion_weight} grams</td>
							</tr> }

							{ p.acf.grams_per_units &&
							<tr>
								<th scope="row">Weight per unit: </th>
								<td colSpan="2">{p.acf.grams_per_units} grams/mls</td>
							</tr>}

							{ p.acf.grams_per_case &&
							<tr>
								<th scope="row">Weight per case: </th>
								<td colSpan="2">{p.acf.grams_per_case} grams/mls</td>
							</tr>}


						</thead>
						<tbody>
							<tr className="table__newrow">
								<td></td>
								<th className="text-right" scope="col">excl VAT</th>
								<th className="text-right" scope="col">incl VAT</th>
							</tr>

							<tr>
								<th scope="row">Cost/portion :</th>
								<td className="text-right">R{Utils.formatPrice(p.cost_per_portion)}</td>
								<td className="text-right">R{Utils.formatPrice(p.cost_per_portion_vat)}</td>
							</tr>

						</tbody>

						<tfoot>
							<tr className="table__newrow">
								<th scope="row">Recommended RSP/portion :</th>
								<td className="text-right" colSpan="2">R{Utils.formatPrice(p.rsp_per_meal)} </td>
							</tr>
						</tfoot>
					</table>
					</div>


					<div>
						<div className="row mb-3">
							<div className="col">
								<TextField
									label="Case Price"
									value={p.case_price}
									type="number"
									id="case-price"
									size="small"
									fullWidth={true}
									variant="outlined"
									onChange={ (e) => this.handleChange(id, e) }
									InputProps={{
										startAdornment: <InputAdornment position="start">R</InputAdornment>,
								}} />
							</div>

							<div className="col">
								<TextField
									label="Case Price with VAT"
									value={Utils.formatPrice(p.case_price_vat)}
									size="small"
									fullWidth={true}
									variant="outlined"
									InputProps={{
										startAdornment: <InputAdornment position="start">R</InputAdornment>,
								}} />
							</div>
						</div>

						<div className="row">
							<div className="col">
								<TextField
									label="Portions/meal"
									value={p.portions_per_meal}
									type="number"
									id="portion-per-meal"
									size="small"
									fullWidth={true}
									variant="outlined"
									onChange={ (e) => this.handleChange(id, e) }
								/>
							</div>
							<div className="col">
								<TextField
									label="% GP"
									value={p.percentage_gp }
									type="number"
									id="percentage-gp"
									size="small"
									fullWidth={true}
									variant="outlined"
									onChange={ (e) => this.handleChange(id, e) }
								/>
							</div>
						</div>



					</div>
				</div>
			</div>
		);
	}
}

export default ProductItem
