import CONFIG from "../config.json";

const Utils = {
	addVat : function(value) {
		value = value * CONFIG.VAT;
		return parseFloat( value );
	},
	formatPrice : function(value) {
		if(value) {
			value = parseFloat(value);
			value = value.toFixed(2)
			return value;
		} else {
			return '0.00';
		}
	}
}

export default Utils