import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Utils from '../Utils';



export class Ingredient extends Component {

	constructor(props) {
		super(props);
		this.handleAddIngredient = this.handleAddIngredient.bind(this);
		this.handleVatCalculation = this.handleVatCalculation.bind(this);
	}

	handleAddIngredient = (e) => {
		let value = e.target.value;

		if(value) {
			this.props.handleAddIngredient(true, value, this.props.type, this.props.index);
		} else {
			this.props.handleAddIngredient(false, value, this.props.type, this.props.index);
			this.props.handlePriceIngredient(0, this.props.type, this.props.index);
		}
	};

	handleVatCalculation(e) {
		let cost = e.target.value;
		this.props.handlePriceIngredient(cost, this.props.type, this.props.index);
	};

	render() {


		return (

			<div className="row mb-sm-1 mb-3">

				<div className="col-12 col-sm">
					<Select
						native
						fullWidth={true}
						variant="outlined"
						value={this.props.chosenIngredient.value}
						onChange={this.handleAddIngredient}>
							<option value="">Choose an ingredient...</option>
							{this.props.ingredients.map((Ingredient, index) => (
								<option key={index} value={Ingredient}>{Ingredient}</option>
							))}
					</Select>
				</div>

				<div className="col">
				{ (this.props.chosenIngredient.added) &&
					<TextField
						label="Cost/Portion"
						type="number"
						value={this.props.chosenIngredient.cost}
						fullWidth={true}
						variant="outlined"
						onInput={(e) => this.handleVatCalculation(e) }
						InputProps={{
							startAdornment: <InputAdornment position="start">R</InputAdornment>,
					}} />
				}
				</div>

				<div className="col">
				{ (this.props.chosenIngredient.added) &&
					<TextField
						label="Cost/Portion with VAT"
						value={Utils.formatPrice( Utils.addVat(this.props.chosenIngredient.cost) )}
						type="number"
						fullWidth={true}
						variant="outlined"
						InputProps={{
							startAdornment: <InputAdornment position="start">R</InputAdornment>,
					}} />
				}

				</div>



			</div>
		)
	}
}

export default Ingredient