import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

import CONFIG from "./config.json";
import axios from "axios";
import './App.scss';

import Products from './Components/Products/Products';
import Logo from './images/logo-white.svg';


export class App extends Component {

  url = CONFIG.SERVER_URL + 'wp-json/acf/' + CONFIG.API_VERSION + '/options/';

	constructor(props) {
		super(props);

		this.state = {
			password : '',
			input : '',
			loggedin : null,
			validation : null
		};

		this.fetchPostData = this.fetchPostData.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
	}

	componentDidMount() {
		this.fetchPostData();
	}

	fetchPostData() {
		axios.get(this.url)
		.then(res => {
			this.setState({
				password : res.data.acf.password
			});
			this.authenticate();
		});
	}

	authenticate() {
		const password = localStorage.getItem('foobar');

		if( password === this.state.password ) {
			// passwords match
			localStorage.setItem('monkey', 'true');
			localStorage.setItem('foobar', this.state.password);
			this.setState({
				loggedin : true
			});
		} else {
			// passwords dont match
			localStorage.setItem('monkey', 'false');
			localStorage.setItem('foobar', null);
			this.setState({
				loggedin : false
			});
		}
	}

	handleClick() {
		if( this.state.password === this.state.input) {
			this.setState({
				loggedin : true,
				validation: true
			});
			localStorage.setItem('monkey', 'true');
			localStorage.setItem('foobar', this.state.password);
		} else {
			this.setState({
				loggedin : false,
				validation: false
			});
			localStorage.setItem('monkey', 'false');
			localStorage.setItem('foobar', null);
		}
	}

	handleChange = (param) => (event) => {
		let value = event.target.value;

		this.setState({
			input : value
		});
	}

	handleKeyUp = (param) => (event) =>  {
		if (event.keyCode === 13) {
			this.handleClick();
		}
  }


	render() {
		return(
			<React.Fragment>
				{ this.state.loggedin === false &&
				<div className="container-fluid">
					<div className="row">

						<div className="login__graphic / col-md-7 / fadeIn delay-400ms"></div>

						<div className="login__fields / col-md-5 text-white">

							<div className="login__form slideInDown">
								<img className="login__logo mb-5" src={Logo} alt="RCL Food Partners" />

								<h2 className="mb-5 / slideInDown delay-600ms">My Plate Cost App</h2>

								<p className="mb-3 text-larger  / slideInDown delay-400ms">Enter password to get started!</p>

								<div className="login__form-fields / slideInDown delay-600ms">
									<TextField
										label="Password"
										type="password"
										id="password"
										autoComplete="new-password"
										fullWidth={true}
										variant="outlined"
										onChange={ this.handleChange() }
										onKeyUp={ this.handleKeyUp() }
									/>

									<button type="button" onClick={this.handleClick} className="btn btn-primary btn-lg ml-sm-2">
										Login
									</button>
								</div>

								{ this.state.validation === false &&
									<p className="error mb-0">Incorrect password</p>
								}
							</div>

						</div>
					</div>
				</div>

				}

				{ this.state.loggedin === true &&
					<div className="product__screen container-fluid">
						<Products />
					</div>
				}

			</React.Fragment>
		);
	}


}


export default App;