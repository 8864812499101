import Gotham from '../fonts/gotham/gothambook-webfont.woff';
import { createMuiTheme } from '@material-ui/core/styles';


const GothamFont = {
 fontFamily: 'Gotham',
 fontStyle: 'normal',
 fontDisplay: 'swap',
 fontWeight: '400',
 src: `
   local('gothambook-webfont'),
   url(${Gotham}) format('ttf')
 `,
//  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};



const Theme = createMuiTheme({
	typography: {
	  fontFamily: ['Gotham', 'sans-serif'].join(','),
	  h1: {
		fontFamily: "Gotham",
	   }
	 },
	 overrides: {
		CssBaseline: {
		  '@global': {
			'@font-face': [GothamFont],
		  },
		}
	  },
	  palette: {
		primary: {
		  main: '#199eda',
		},
	  },
   })

export default Theme;