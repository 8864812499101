import React, { PureComponent } from 'react'

export class SearchNoResults extends PureComponent {
	render() {
		return (
			<div className="content-box mb-3">
				<p className="text-center">Add some products first...</p>
			</div>
		)
	}
}

export default SearchNoResults